import axios from "axios";
import {URL} from "../Constants";

export default class PlayerService {
    constructor(gameId) {
        this.game = gameId;
        this.url = URL + gameId + "/"
    }

    async getClients() {
        try {
            let response = await axios.get(this.url);
            return response.data.clients;
        } catch (e) {
            console.warn("Failed to get clients", e);
        }
    }

    async registerClient(rtcID, connectionId) {
        try {
            console.log("Registering client");
            await axios.get(this.url + `register/${rtcID}/${connectionId}`)
        }  catch (e) {
            console.warn("Failed to register client", e);
        }
    }

    async removeClient(rtcID) {
        try {
            await axios.get(this.url + `remove/${rtcID}`)
        }  catch (e) {
            console.warn("Failed to register client", e);
        }
    }

    async replaceClient(rtcID, connId) {
        try {
            await axios.get(this.url + `replace/${rtcID}/${connId}`)
        }  catch (e) {
            console.warn("Failed to register client", e);
        }
    }

    async keepAlive(rtcID) {
        try {
            await axios.get(this.url + `pulse/${rtcID}`)
        }  catch (e) {
            console.warn("Failed to register client", e);
        }
    }

}
