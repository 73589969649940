import * as _ from 'lodash';

function handleUpdate (parent, data) {
    switch (data.type) {
        case "update":
            switch(data.data.type) {
                case "other":
                    console.log("other message received", data.data.payload);
                    acknowledge(parent, data.updateId, data.from);
                    break;
                case "score_update": {
                    document.dispatchEvent(new CustomEvent('SCORE_UPDATE', {detail: data.data.payload}));
                    acknowledge(parent, data.updateId, data.from);
                    break;
                }
                case "scores_overwrite": {
                    if(data.data.request === 'scores_request') {
                        parent.isRequestingScore = false;
                        document.dispatchEvent(new CustomEvent('UPDATE_STATUS', {detail: {field: 'isSyncingWithPeers', value: parent.isRequestingScore || parent.reconnecting}}));
                    }
                    document.dispatchEvent(new CustomEvent('SCORES_OVERWRITE', {detail: data.data.payload}));
                    acknowledge(parent, data.updateId, data.from);
                    break;
                }
                default:
                    console.log("Unknown inner data type", data.data.type)
            }
            break;
        case "ping":
            const time = Date.now();
            const updateId = parent.generateUpdateId();
            for (const peer of parent.peers) {
                if(peer.id === data.from) {
                    peer.pong(data.data, updateId);
                    console.log("Replying to ping from", data.from, "with current time at", time - data.data.pingTime, "ms")
                    break;
                }
            }
            break;
        case "pong":
            // if(players[data.from]) {
            //     players[data.from].connectionState = 1;
            //     parent.setState({
            //         players
            //     })
            // }
            console.log("Ping time from", data.from, "time to receive pong:", data.data.pongTime - data.data.pingTime, "ms. Time from ping to now", Date.now() - data.data.pingTime)
            break;
        case "scores_request": {
            console.log('Scores requested by', data.from)
            parent.scoresRequested(data.from);
            break;
        }
        case 'ack': {
            console.log('Acknowledged', `${data.data}:${data.from}`, parent.unacknowledgedUpdates);
            parent.unacknowledgedUpdates = _.without(parent.unacknowledgedUpdates, `${data.data}:${data.from}`)
            document.dispatchEvent(new CustomEvent('UPDATE_STATUS', {detail: {field: 'outStandingRequests', value: parent.unacknowledgedUpdates.length}}));
            break;
        }
        default:
            console.log("Unknown type", data.type)
    }
}
function sendToPeers (parent, data) {
    const updateId = parent.generateUpdateId();
    for (const peer of parent.peers) {
        parent.unacknowledgedUpdates.push(`${updateId}:${peer.id}`)
        peer.sendToPlayer(data, updateId);
    }
    document.dispatchEvent(new CustomEvent('UPDATE_STATUS', {detail: {field: 'outStandingRequests', value: parent.unacknowledgedUpdates.length}}));
}
function acknowledge (parent, updateId, from) {
    for (const peer of parent.peers) {
        if(peer.id === from && !peer.isInvalid) {
            console.log('Acknowledging', updateId, parent.unacknowledgedUpdates);
            peer.acknowledge(updateId, parent.generateUpdateId());
            break;
        }
    }
}
const update = {
    handleUpdate,
    sendToPeers,
    acknowledge,
};
export default update;
