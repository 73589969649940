import axios from "axios";
import {URL} from "./Constants"

export default class GameService {
    constructor() {
        this.url = URL
    }

    async checkIfGameExists(gameId) {
        try {
            let response = await axios.get(this.url + gameId);
            return response.data.clients;
        } catch (e) {
            console.warn("Failed to get clients", e);
        }
    }

    async createGame(gameId, playerUUID) {
        try {
            await axios.get(this.url + `create/${gameId}/${playerUUID}`)
        }  catch (e) {
            console.warn("Failed to register client", e);
        }
    }

}
