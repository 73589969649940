import React from "react";
import Cookies from 'universal-cookie';
import {v4 as uuidv4} from 'uuid';
import * as _ from 'lodash';
import PlayerService from "./PlayerService";
import ConnectionChecker from "../ConnectionChecker";
import "./Game.scss";
import {Link, useNavigate, useParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {library} from '@fortawesome/fontawesome-svg-core'
import {faCopy, faEdit, faPlus} from '@fortawesome/free-solid-svg-icons'
import Group from './RTC/Group';

library.add(faCopy, faEdit, faPlus);

function withParams(Component) {
    return props => <Component {...props} params={useParams()} navigate={useNavigate()}/>;
}


const cookies = new Cookies();

function handleScoreUpdate(event) {
    event.stopPropagation();
    if(this.processedUpdates[`${event.type}:${event.timeStamp}`] !== 1) {
        this.processedUpdates[`${event.type}:${event.timeStamp}`] = 1;
        console.log('SCORE_UPDATE', event, this.state.scores);
        this.updateScore(this.state.scores, event.detail)
    }
}

function handleScoresOverwrite(event) {
    event.stopPropagation();
    if(this.processedUpdates[`${event.type}:${event.timeStamp}`] !== 1) {
        this.processedUpdates[`${event.type}:${event.timeStamp}`] = 1;
        console.log('SCORES_OVERWRITE', event, this.state.scores)
        if(event.detail?.length > 0) {
            this.setState({
                scores: event.detail
            })
        }
    }
}

function handleStatusUpdate(event) {
    event.stopPropagation();
    if(this.processedUpdates[`${event.type}:${event.timeStamp}`] !== 1) {
        this.processedUpdates[`${event.type}:${event.timeStamp}`] = 1;
        this.setState({
            [event.detail.field]: event.detail.value
        })
    }
}

class Game extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false,
            scores: [],
            selectedScore: undefined,
            name: cookies.get("player-name"),
            gameId: props.params.gameId,
            uuid: cookies.get("my-uuid"),
            isSyncingWithPeers: false,
            connectedPeers: [],
            outStandingRequests: 0,
            lastUpdatedByUser: undefined
        }
        console.log("State UUID", this.state.uuid)
        this.bs = new PlayerService(props.params.gameId);
        this.processedUpdates = {};
        if (!this.state.uuid || this.state.uuid === "undefined") {//this needs to be double equals
            console.log("Generating new uuid", this.state.uuid)
            this.state.uuid = this.generateUUID("my-uuid");
        }
        if(cookies.get(`game:${props.params.gameId}`)) {
            console.log('Retrieving game from local storage', cookies.get(`game:${props.params.gameId}`));
            this.state.scores = cookies.get(`game:${props.params.gameId}`);
        }
        this.group = new Group(props.params.gameId, this.state.uuid, this);
    }

    componentDidMount() {
        if (this.state.scores.length === 0) {
            this.addScore();
        }
        document.removeEventListener('SCORE_UPDATE', handleScoreUpdate);
        document.removeEventListener('SCORES_OVERWRITE', handleScoresOverwrite);
        document.removeEventListener('UPDATE_STATUS', handleStatusUpdate);

        document.addEventListener('SCORE_UPDATE', handleScoreUpdate.bind(this));
        document.addEventListener('SCORES_OVERWRITE', handleScoresOverwrite.bind(this));
        document.addEventListener('UPDATE_STATUS', handleStatusUpdate.bind(this));
    }

    componentWillUnmount() {
        document.removeEventListener('SCORE_UPDATE', handleScoreUpdate);
        document.removeEventListener('SCORES_OVERWRITE', handleScoresOverwrite);
        document.removeEventListener('UPDATE_STATUS', handleStatusUpdate);
    }

    generateUUID(cookie) {
        let uuid = uuidv4();
        if (cookie) {
            console.log("Saving fresh UUID", cookie);
            cookies.set(cookie, uuid, {path: '/'})
        }
        return uuid;
    }

    generateUpdateId() {
        return this.state.connectionId + ":" + Date.now();
    }

    addScore() {
        const newScores = [...this.state.scores, this.generateNewScore()];
        this.setState({
            scores: newScores,
            lastUpdatedByUser: Date.now()
        })
        this.group.sendScores(newScores);
        this.storeGame(newScores);
    }

    generateNewScore() {
        return {
            id: this.generateUUID(),
            score: 0,
            name: `Player ${this.state.scores.length + 1}`,
            isEditingScore: false,
            isEditingName: false
        };
    }

    updateScore(scores, score) {
        for (let i = 0; i < scores.length; i++) {
            if (scores[i].id === score.id) {
                scores[i] = score;
            }
        }
        const newScores = [...scores]
        this.setState({
            scores: newScores,
            lastUpdatedByUser: Date.now()
        });
        this.storeGame(newScores);
    }

    storeGame(scores) {
        cookies.set(`game:${this.state.gameId}`, scores ? scores : this.state.scores, {path: '/'})
    }

    render() {
        const scores = this.state.scores;
        return (
            <div className='game' style={{height: '100%'}} onClick={() => {
                let updateState = false;
                for (const score of scores) {
                    if(score.isEditingScore) {
                        score.isEditingScore = false;
                        updateState = true;
                    }
                    if(score.isEditingName) {
                        score.isEditingName = false;
                        updateState = true;
                    }
                }
                if(updateState) {
                    this.setState({
                        scores: [...scores],
                        lastUpdatedByUser: Date.now()
                    });
                }
            }}>
                <ConnectionChecker/>
                <Link style={{textDecoration: "none"}} to={"/"}><h1
                    style={{color: "#C23447", marginBottom: 30}}>Pontie</h1></Link>
                <div className="sidebyside" style={{justifyContent:'space-between'}}>
                    <div title="This is your games unique ID. Click to copy this games URL to your clipboard"
                         className="game-section">
                        <span className="clickable" onClick={() => {
                            navigator.clipboard.writeText(window.location.href)
                        }}>Share Game <FontAwesomeIcon className="icon-small" icon="fa-copy"/></span>
                    </div>
                    <div className='game-section'>
                        <span title='Number of connections open to other clients' style={{paddingRight: '10px'}}>Peers: {this.state.connectedPeers.length}</span>
                        {this.state.connectedPeers.length > 0 ? <span title='Reconnecting or requesting a score overright from other peers' style={{paddingRight: '10px'}}>Hard Syncing: {this.state.isSyncingWithPeers.toString()}</span> : null}
                        {this.state.connectedPeers.length > 0 && this.state.outStandingRequests > 2 ? <span title='Number of updates sent and not yet acknowledged'>Unacknowledged Updates: {this.state.outStandingRequests.toString()}</span> : null}
                    </div>
                </div>
                <div className="score-section">
                    <div className='heading'>
                        <div className='left'>
                            <h3 style={{display: 'flex'}}>Scores: <button title='Another another score to the list' className='clickable drift right' onClick={(event) => {
                                this.addScore();
                                event.stopPropagation();
                            }}>
                                Add Score
                            </button></h3>
                        </div>
                        <div className='right'>
                            <button title='Reconnect to peers' className='clickable drift right' onClick={(event) => {
                                this.group.reconnect();
                                event.stopPropagation();
                            }}>
                                Reconnect
                            </button>
                            {this.state.connectedPeers.length > 0 ? <button title='Send your scores to other players' className='clickable drift right' onClick={(event) => {
                                this.group.sendScores(this.state.scores);
                                event.stopPropagation();
                            }}>
                                Push scores
                            </button> : null}
                        </div>
                    </div>
                    <div>
                        {_.map(scores, (score) => {
                            return (
                                <div key={score.id} className='sidebyside score player'>
                                    <div className="button-group negative">
                                        {_.map([1, 5, 10], (item) => {
                                            return <button key={item} title={`Remove ${item} to your score`} className="minus clickable"
                                                           onClick={(event) => {
                                                               score.score = score.score - item;
                                                               this.updateScore(scores, score);
                                                               this.group.sendScore(score);
                                                               event.stopPropagation();
                                                           }}>-{item}
                                            </button>
                                        })}
                                    </div>
                                    <div className="information" style={{width: '380px'}}>
                                        <div>
                                            <div className='player-score'>
                                                <span className="clickable" title="Edit this score" onClick={(event) => {
                                                    if(!score.isEditingName) {
                                                        score.isEditingScore = !score.isEditingScore;
                                                        score.isEditingName = false;
                                                        this.updateScore(scores, score);
                                                        event.stopPropagation();
                                                    }
                                                }}>
                                                    {score.isEditingName ? <input onChange={(event) => {
                                                        score.name = event.target.value;
                                                        this.updateScore(scores, score);
                                                        this.group.sendScore({
                                                            ...score,
                                                            isEditingName: false
                                                        });
                                                        event.stopPropagation();
                                                    }} onClick={(event) => {
                                                        event.stopPropagation();
                                                    }}/> : `${score.name}`}
                                                </span>
                                                <span>{': '}</span>
                                                <span className="clickable" style={{fontWeight: 'bold'}} onClick={(event) => {
                                                    if (!score.isEditingScore) {
                                                        score.isEditingScore = !score.isEditingScore;
                                                        score.isEditingName = false;
                                                        this.updateScore(scores, score);
                                                        event.stopPropagation();
                                                    }
                                                }}>
                                                    {score.isEditingScore ? <input type={'number'} onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            console.log("Preventing custom score as its not a number")
                                                            event.preventDefault();
                                                        }
                                                    }} onChange={(event) => {
                                                        score.score = Number(event.target.value);
                                                        this.updateScore(scores, score);
                                                        this.group.sendScore({
                                                            ...score,
                                                            isEditingScore: false
                                                        });
                                                        event.stopPropagation();
                                                    }} onClick={(event) => {
                                                        event.stopPropagation();
                                                    }}/> : `${score.score}`}
                                                </span>
                                                <span className="clickable" onClick={(event) => {
                                                    score.isEditingScore = !score.isEditingScore;
                                                    score.isEditingName = false;
                                                    this.updateScore(scores, score);
                                                    event.stopPropagation();
                                                }}>
                                                    <FontAwesomeIcon className="icon-large" icon="fa-edit"/>
                                                </span>
                                            </div>
                                            <div className='options'>
                                                <button className="clickable" onClick={(event) => {
                                                    score.isEditingName = !score.isEditingName;
                                                    score.isEditingScore = false;
                                                    this.updateScore(scores, score);
                                                    event.stopPropagation();
                                                }}>{score.isEditingName ? 'Save Name' : 'Edit Name'}</button>
                                                <button className="clickable" onClick={(event) => {
                                                    score.score = 0;
                                                    this.updateScore(scores, score);
                                                    this.group.sendScore(score);
                                                    event.stopPropagation();
                                                }}>Reset Score</button>
                                                {scores.length > 1 ? <button className="clickable" onClick={(event) => {
                                                    const newScores = _.filter(scores, (o) => {
                                                        return o.id !== score.id;
                                                    });
                                                    this.setState({
                                                        scores: newScores,
                                                        lastUpdatedByUser: Date.now()
                                                    })
                                                    this.group.sendScores(newScores);
                                                    this.storeGame();
                                                    event.stopPropagation();
                                                }}>Remove</button> : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="button-group positive">
                                        {_.map([1, 5, 10], (item) => {
                                            return <button key={item} title={`Add ${item} to your score`} className="add clickable"
                                                           onClick={(event) => {
                                                               score.score = score.score + item;
                                                               this.updateScore(scores, score);
                                                               this.group.sendScore(score);
                                                               event.stopPropagation();
                                                           }}>+{item}
                                            </button>
                                        })}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        )
    }
}

export default withParams(Game);
