import './App.css';
import React from 'react';
import { Routes, Route } from "react-router-dom";
import Home from "./Home";
import Game from "./Game/Game";

class App extends React.Component{

    componentDidMount(){
        document.title = "Pontie: Game Score Keeper"
    }

    render() {
      return (
          <div className="App">
              <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="game/:gameId" element={<Game />} />
              </Routes>
          </div>
      );
  }
}

export default App;
