
export default class Peer {
    constructor(peerId, connectionId, localId, parentConnection) {
        this.id = peerId;
        this.connectionId = connectionId;
        this.localId = localId;
        this.parentConnection = parentConnection;
        this.isInvalid = false;
    }

    ping(updateId) {
        const connection = this.parentConnection.getConnection(this.id, this.connectionId);
        if(connection && connection.open) {
            connection.send({
                from: this.localId,
                data: {
                    pingTime: Date.now()
                },
                type: 'ping',
                updateId: updateId
            })
        } else {
            this.close();
        }
    }

    pong(ping, updateId) {
        const connection = this.parentConnection.getConnection(this.id, this.connectionId);
        if(connection && connection.open) {
            connection.send({
                from: this.localId,
                data: {
                    pingTime: ping.pingTime,
                    pongTime: Date.now()
                },
                type: 'pong',
                updateId: updateId
            })
        } else {
            this.close();
        }
    }

    sendToPlayer(data, updateId) {
        const connection = this.parentConnection.getConnection(this.id, this.connectionId);
        if(connection && connection.open) {
            console.log('Sending', data.type, 'to', this.id)
            connection.send({
                from: this.localId,
                data: data,
                type: 'update',
                updateId: updateId
            })
        } else {
            console.log('invalid connection', connection);
            this.close();
        }
    }

    acknowledge(updateToAck, updateId) {
        const connection = this.parentConnection.getConnection(this.id, this.connectionId);
        if(connection && connection.open) {
            connection.send({
                from: this.localId,
                type: 'ack',
                data: updateToAck,
                updateId: updateId
            })
        } else {
            this.close();
        }
    }

    requestScore(updateId) {
        const connection = this.parentConnection.getConnection(this.id, this.connectionId);
        if(connection && connection.open) {
            console.log('request scores', 'from', this.id)
            connection.send({
                from: this.localId,
                type: 'scores_request',
                updateId: updateId
            })
        } else {
            console.log('invalid connection', connection);
            this.close();
        }
    }

    close() {
        const connection = this.parentConnection.getConnection(this.id, this.connectionId);
        if(connection && connection.open) {
            connection.send({
                from: this.localId,
                type: 'closing'
            })
            connection.off('open')
            connection.off('data')
            connection.close();
        }
        this.isInvalid = true;
    }
}
