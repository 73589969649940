import {Peer as PeerJS} from "peerjs";
import Peer from "./Peer";
import PlayerService from '../PlayerService';
import Update from './Update';
import * as _ from 'lodash';

export default class Group {
    constructor(gameId, playerId, parent) {
        this.backend = new PlayerService(gameId);
        this.id = playerId;
        this.peers = [];
        this.connected = false;
        this.reconnecting = false;
        this.processedUpdates = {};
        this.retryCount = 0;
        this.requestingScoresSince = undefined;
        this.game = parent
        this.isRequestingScore = false;
        this.unacknowledgedUpdates = [];
        this.connect();
    }

    async connect(reconnecting) {
        console.log("Connecting")
        this.localConnection = new PeerJS();
        this.localConnection.on("open", async (id) => {
            console.log("I am open", id);
            this.connectionId = id;
            if(reconnecting) {
                await this.backend.replaceClient(this.id, id)
            } else {
                await this.backend.registerClient(this.id, id);
            }
            const clients = await this.backend.getClients();
            if(clients) {
                for (const client of clients) {
                    if(client !== id) {
                        this.connectToPeer(client);
                    }
                }
            }
        })
        this.localConnection.on('connection', (connection) => {
            connection.on('open', () =>{
                if(_.findIndex(this.peers, (o) => {
                    return o.id === connection.peer;
                }) === -1) {
                    console.log("Remote connection opened", connection)
                    this.peers.push(new Peer(connection.peer, connection.connectionId, this.connectionId, this.localConnection));
                    document.dispatchEvent(new CustomEvent('UPDATE_STATUS', {detail: {field: 'connectedPeers', value: _.map(this.peers, o => o.id)}}));
                }
            })
            connection.on('data', this.onDataReceived.bind(this))
        })

        this.localConnection.on('error', this.onErrorReceived.bind(this))
        if(!this.connected) {
            this.connected = true;
            // this.pingPeers();
        }
        this.reconnecting = false;
        document.dispatchEvent(new CustomEvent('UPDATE_STATUS', {detail: {field: 'isSyncingWithPeers', value: this.isRequestingScore || this.reconnecting}}));
    }

    destroy() {
        if(this.localConnection) {
            console.log('Destroying connection');
            for (const peer of this.peers) {
                peer.close()
            }
            this.localConnection.off('error');
            this.localConnection.off('data');
            this.localConnection.off('connection');
            this.localConnection.off('open');
            this.localConnection.disconnect();
            this.localConnection.destroy();
            this.requestingScoresSince = undefined;
            this.isRequestingScore = false;
            this.unacknowledgedUpdates = [];
            this.processedUpdates = {};
            this.peers = [];
            this.localConnection = null;
        } else {
            console.warn('Was meant to destroy but local connection is null', this.localConnection)
        }
    }

    connectToPeer(id) {
        if(this.localConnection) {
            console.log("Connecting to", id, 'i am', this.connectionId)
            const remoteConnection = this.localConnection.connect(id);
            if(remoteConnection) {
                remoteConnection.on("open", () => {
                    if(_.findIndex(this.peers, (o) => {
                        return o.id === remoteConnection.peer;
                    }) === -1) {
                        console.log("I have opened", remoteConnection);
                        const peer = new Peer(remoteConnection.peer, remoteConnection.connectionId, this.connectionId, this.localConnection);
                        this.peers.push(peer);
                        document.dispatchEvent(new CustomEvent('UPDATE_STATUS', {detail: {field: 'connectedPeers', value: _.map(this.peers, o => o.id)}}));
                        if(!this.isRequestingScore) {
                            this.isRequestingScore = true;
                            this.requestingScoresSince = Date.now();
                            document.dispatchEvent(new CustomEvent('UPDATE_STATUS', {detail: {field: 'isSyncingWithPeers', value: this.isRequestingScore || this.reconnecting}}));
                            peer.requestScore(this.generateUpdateId());
                        }
                    }
                })
                remoteConnection.on('data', this.onDataReceived.bind(this));
            }
        }
    }

    reconnect() {
        this.destroy();
        if(!this.reconnecting) {
            this.reconnecting = true;
            document.dispatchEvent(new CustomEvent('UPDATE_STATUS', {detail: {field: 'isSyncingWithPeers', value: this.isRequestingScore || this.reconnecting}}));
            this.connect(true);
        }
    }

    generateUpdateId() {
        return this.connectionId + ":" + Date.now();
    }

    onDataReceived(data) {
        if(this.processedUpdates[data.updateId]) {
            return
        }
        console.log("Data Received from", data.from, data.data, 'i am', this.connectionId)
        this.processedUpdates[data.updateId] = 1;
        if(data.data.lastUpdatedByUser && this.game.state.lastUpdatedByUser && this.game.state.lastUpdatedByUser > data.data.lastUpdatedByUser){
            console.log('Rejecting update because its too old', this.game.state.lastUpdatedByUser)
            return
        }//we have are more important update so we should ignore the other devices

        if(this.retryCount > 0) {
            this.retryCount = 0;
        }
        Update.handleUpdate(this, data);
    }

    onErrorReceived(err) {
        console.log("Error occurred", err.type)
        this.reconnect();
    }

    sendPing() {
        const updateId = this.generateUpdateId();
        for (const peer of this.peers) {
            peer.ping(updateId);
        }
    }

    send(payload) {
        this.peers = _.filter(this.peers, o => !o.isInvalid)
        Update.sendToPeers(this, this._generateRTCPayload(payload, 'other'))
    }

    sendScore(score) {
        this.checkRequesting();
        this.peers = _.filter(this.peers, o => !o.isInvalid)
        document.dispatchEvent(new CustomEvent('UPDATE_STATUS', {detail: {field: 'connectedPeers', value: _.map(this.peers, o => o.id)}}));
        Update.sendToPeers(this, this._generateRTCPayload(score, 'score_update', this.game.state.lastUpdatedByUser))
    }

    sendScores(scores) {
        this.peers = _.filter(this.peers, o => !o.isInvalid)
        document.dispatchEvent(new CustomEvent('UPDATE_STATUS', {detail: {field: 'connectedPeers', value: _.map(this.peers, o => o.id)}}));
        Update.sendToPeers(this, this._generateRTCPayload(scores, 'scores_overwrite', this.game.state.lastUpdatedByUser))
    }

    scoresRequested(requestorId) {
        this.checkRequesting();
        if(!this.isRequestingScore) {//we should not be giving other people our score will we are requesting someone elses
            for (const peer of this.peers) {
                if(peer.id === requestorId && !peer.isInvalid) {
                    peer.sendToPlayer(this._generateRTCPayload(this.game.state.scores, 'scores_overwrite', this.game.state.lastUpdatedByUser, 'scores_request'), this.generateUpdateId());
                    break;
                }
            }
        } else {
            console.log('Not responding with scores as I am requesting scores')
        }
    }

    checkRequesting() {
        if(this.isRequestingScore && Date.now() - this.requestingScoresSince > 15000) {
            this.isRequestingScore = false;
        }
        document.dispatchEvent(new CustomEvent('UPDATE_STATUS', {detail: {field: 'isSyncingWithPeers', value: this.isRequestingScore || this.reconnecting}}));
    }

    _generateRTCPayload(payload, type, lastUpdatedByUser, request) {
        return {
            type,
            payload,
            lastUpdatedByUser,
            request
        }
    }
}
