import React from "react";
import home1 from "./resources/images/home1.jpg"
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'universal-cookie';
import {Link, useNavigate} from 'react-router-dom'
import GameService from "./GameService";
function withNav(Component) {
    return props => <Component {...props} navigate={useNavigate()} />;
}
const cookies = new Cookies();
class Home extends React.Component{
    constructor(props) {
        super(props);
        this.uuid = cookies.get("my-uuid")
        if(!this.uuid) {
            this.uuid = this.generateUUID("my-uuid");
        }
        this.state = {
            gameId: '',
            showJoin: false
        }
        this.gameService = new GameService();
    }

    generateUUID(cookie) {
        console.log("Generating fresh UUID", cookie);
        let uuid = uuidv4();
        if(cookie) {
            cookies.set(cookie, this.uuid, { path: '/' })
        }
        return uuid;
    }

    render() {
        return (
            <div>
                <div>
                    <h1 style={{color:"#C23447", marginBottom: 5}}>Pontie</h1>
                    <h2 style={{marginTop: 5}}>Boardgame score counter</h2>
                </div>
                <img alt="Boardgame" style={{height:"30%", width: '99%', objectFit: 'cover'}} src={home1}/>
                <div style={{display: 'flex', justifyContent: 'space-evenly', paddingTop: '40px', flexWrap: "wrap"}}>
                    <button style={{width: 200, height: 40, fontSize: 18, marginTop: 5}} onClick={async () => {
                        let uuid = uuidv4();
                        this.setState({
                            gameId: uuid
                        })
                        await this.gameService.createGame(uuid, this.uuid);
                        this.props.navigate("/game/"+uuid);
                    }}>Create a new game</button>
                    <button style={{width: 200, height: 40, fontSize: 18, marginTop: 5}} onClick={() => {
                        this.setState({
                            showJoin: !this.state.showJoin
                        })
                    }}>Join an existing game</button>
                    {this.state.showJoin && <div>
                        <label>Enter game ID:  <input onChange={(event) => {
                            this.setState({
                                gameId: event.target.value
                            })
                        }
                        }/> {this.state.gameId.length === 36 && <Link to={"game/" + this.state.gameId}><button>Join</button></Link>}</label>
                    </div>}
                </div>
                <div>
                    <p style={{padding: 10}}>Pontie is a boardgame score, life or point counter that uses the same high speed, low latency technology you find inside video calling applications to provide the most responsive experience</p>
                    <div>
                        <h4>How to Use:</h4>
                        <ol>
                            <li>Create a game</li>
                            <li>Share the game link with your friends so they can join</li>
                            <li>Track your score, or edit your friends</li>
                        </ol>
                    </div>
                </div>
            </div>
        );
    }
}

export default withNav(Home)
