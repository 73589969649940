import React from 'react';
import axios from 'axios';
import {BASE_URL} from './Constants';

/**
 * ConnectionState
 * 0 is connecting
 * -1 is not connected
 * 1 is connected
 */
let connected = false;
export default class ConnectionChecker extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            connectionState: 1,
            doCheck: true,
        }
    }

    componentDidMount() {
        if(!connected) {
            connected = true;
            this.doCheck()
        }
    }

    async doCheck() {
        if(!this.state.doCheck) {
            return;
        }
       try {
           console.log("Checking internet connection")
           if(this.state.connectionState === -1) {
               this.setState({
                   connectionState: 0
               })
           }
           const res = await axios.get(`${BASE_URL}ping`)
           if(res.status === 200 && res?.data?.message) {
               this.setState({
                   connectionState: 1
               })
           } else {
               this.setState({
                   connectionState: -1
               })
           }
       } catch (e) {
           this.setState({
               connectionState: -1
           })
           console.log('Failed to connect with error', e)
       } finally {
            const timeout = Math.floor(Math.random() * 60000) + 10000;
            console.log('Waiting', timeout/1000, 'seconds before checking internet again')
           setTimeout(this.doCheck.bind(this), timeout);
       }
    }

    processConnectionState(connectionState){
        switch (connectionState) {
            case 1:
                return <span>Connected to the internet</span>
            case 0:
                return <span>Checking internet connection</span>
            case -1:
                return <span>Internet connection Lost</span>
            default:
                console.log('Unrecognised value')
        }
    }
    render(){
        if(this.state.connectionState !== 1) {
            return <div style={{fontSize: 18, width: '100%', height: '20px', paddingTop: '5px', paddingBottom: '5px', position: 'fixed', top: 0, left: 0, zIndex: 999, margin: 0, backgroundColor: this.state.connectionState === -1 ? 'red' : 'gray'}}>
                {this.processConnectionState(this.state.connectionState)}
            </div>
        }
    }
}
